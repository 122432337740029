<template>
    <div>
        <div class="ft20 cl-main ftw600">站点管理</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <div class="mt10">
                        <a-form-item label="站点信息">
                            <a-input v-model="search.name" placeholder="请输入站点名称/地址"></a-input>
                        </a-form-item>

                        <a-form-item label="站点分类">
                            <div>
                                <a-select style="width: 150px" placeholder="全部分类" v-model="search.site_type_id" mode="multiple">
                                    <a-select-option :value="0" :key="-1">
                                        选择分类
                                    </a-select-option>
                                    <a-select-option :value="item.site_type_id" :key="index"
                                                     v-for="(item,index) in type_list">{{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </div>
                        </a-form-item>
                        <a-form-item label="营业状态">
                            <a-select v-model="search.is_run" style="width:100px" placeholder="状态">
                                <a-select-option :value="-1" :key="0">全部</a-select-option>
                                <a-select-option :value="0" :key="1">营业中</a-select-option>
                                <a-select-option :value="1" :key="2">暂停营业</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="站点类型">
                            <a-select v-model="search.site_type" style="width:120px" placeholder="选择自营或者入驻">
                                <a-select-option :value="0" :key="-1" >
                                    选择类型
                                </a-select-option>
                                <a-select-option :value="1" :key="0">官方自营</a-select-option>
                                <a-select-option :value="2" :key="1">入驻站点</a-select-option>
                            </a-select>
                        </a-form-item>
                    </div>
                    <div class="mt10">
                        <a-form-model-item label="站点地址">
                            <a-select v-model="search.province_id" style="width: 120px" @change="getRegion" >
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option  v-for="province in provinceData" :key="province.id" data-level="2">
                                    {{ province.name }}
                                </a-select-option>
                            </a-select>

                            <a-select style="width: 120px" v-model="search.city_id" @change="getRegion" placeholder="请选择市">
                                <a-select-option  v-for="city in cityData" :key="city.id" data-level="3">
                                    {{ city.name }}
                                </a-select-option>
                            </a-select>

                            <a-select v-model="search.district_id" style="width: 120px" @change="getRegion" placeholder="请选择区/县">
                                <a-select-option v-for="district in districtData" :key="district.id" data-level="4">
                                    {{ district.name }}
                                </a-select-option>
                            </a-select>
                            <a-select v-model="search.street_id" style="width: 160px" placeholder="请选择所属街道">
                                <a-select-option v-for="street in streetData" :key="street.id" data-level="5">
                                    {{ street.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-item>
                            <a-button @click="searchAct" icon="search" type="primary">查询</a-button>
                            <a-button @click="cancelAct" icon="close" class="ml10">取消</a-button>
                        </a-form-item>
                    </div>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" icon="plus" @click="addSiteAct()">添加站点</a-button>

                        <a-button class="ml10" icon="export" @click="siteManageExport()">导出站点经营情况</a-button>

                        <a-button type="dashed" icon="export" class="ml10" @click="siteBaseExport()">导出站点基本情况</a-button>
                    </div>


                    <div class="mt20">
                        <div class="wxb-table-gray">
                            <a-table rowKey="shop_id" :columns="columns" :pagination="pagination"
                                     @change="handleTableChange"
                                     :data-source="datas" :loading="loading">

                                <div class="flex alcenter left" slot="project" slot-scope="record">
                                    <img :src="record.site_logo" class="site-index-cover-img"/>
                                    <div class="ml5" style="text-align: left;">
                                        <div style="color: #ff702c;">{{ record.name }}
                                          <a-tag>
                                            长者{{ record.elder_num }} 人
                                          </a-tag>
                                        </div>
                                        <div class="mt2 ft12">电话：{{ record.site_phone }}</div>
                                        <div class="mt2 ft12">地址：{{ record.address }}</div>
                                    </div>
                                </div>

                                <div class="flex alcenter left" slot="station_master" slot-scope="record">
                                    <div class="ml5" style="text-align: left;">
                                        <div class="mt4 ft12">
                                            名称：
                                            <span v-if="record.station_master" color="cyan">
                                                {{ record.station_master }}
                                            </span>
                                            <span v-else color="red">
                                                未绑定
                                            </span>
                                        </div>
                                        <div class="mt4 ft12">编号：{{ record.site_no }}</div>
                                        <div class="mt4 ft12">创建：{{ record.add_time_format }}</div>
                                    </div>
                                </div>
                                <div slot="site_type_id_arr" slot-scope="site_type_id_arr" style="text-align: left;">
                                    <p class="mb5 ft12" v-for="(val,index) in site_type_id_arr" color="purple" :key="index">
                                        {{val}}
                                    </p>
                                </div>
                                <div class="flex alcenter center" slot="isOpen" slot-scope="is_open">
                                    <a-tag v-if="is_open == 1" color="green">
                                        已启用
                                    </a-tag>
                                    <a-tag v-if="is_open == 0" color="red">
                                        已关闭
                                    </a-tag>
                                </div>
                                <div class="flex alcenter center" slot="isRun" slot-scope="is_run">
                                    <a-tag v-if="is_run == 0" color="green">
                                        营业中
                                    </a-tag>
                                    <a-tag v-if="is_run == 1" color="red">
                                        暂停营业
                                    </a-tag>
                                </div>

                                <div class="flex alcenter center" slot="type" slot-scope="type">
                                    {{ type != null ? type.name : '暂无分类' }}
                                </div>

                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;"
                                                       @click="editProjectAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="del(record)">
                                                        <i class="iconfont ft14 icondelete"></i>
                                                        <span class="ml10">删除</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';

export default {
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            eUrl:'',
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
                showTotal:total=>`共${total} 条数据`
            },
            type_list: [],
            provinceData:[],
            cityData:{},
            districtData:{},
            streetData:{},
            search: {
                name: '',
                site_type_id: [],
                is_run:-1,
                site_type:0,//站点类型
                province_id:0,
                city_id:'',
                district_id:'',
                street_id:'',
            },
            columns: [
              {title: '站点信息', key: 'project', align: 'left',width: 400, scopedSlots: {customRender: 'project'}},
                {title: '站长', key: 'station_master', width: 230, align: 'left', scopedSlots: {customRender: 'station_master'}},
                {title: '站点分类', dataIndex: 'site_type_id_arr', align: 'left', scopedSlots: {customRender: 'site_type_id_arr'}},
                {title: '是否启用', dataIndex: 'is_open', align: 'center', ellipsis: true, scopedSlots: {customRender: 'isOpen'}},
                {title: '营业状态', dataIndex: 'is_run', align: 'center', ellipsis: true, scopedSlots: {customRender: 'isRun'}},
                {title: '操作', key: 'action', align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    created(){
        this.getRegion(0,1,true);
        this.eUrl = process.env.VUE_APP_BASE_API + "housekeeping/platform/site/exportData?client_env="+localStorage.getItem('client-env-platform')+"&auth_token=" + encodeURIComponent(localStorage.getItem('auth-token'))+'&client=PC';
    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/site/getSiteLists', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                name: this.search.name,
                site_type_id: this.search.site_type_id,
                is_run: this.search.is_run,
                site_type: this.search.site_type,
                province_id: this.search.province_id,
                city_id: this.search.city_id,
                district_id: this.search.district_id,
                street_id: this.search.street_id,
            }).then(res => {
                this.pagination.total = res.total;
                this.datas = res.list;
                if(res.type_list instanceof Object){
                    this.type_list = res.type_list;
                }
                this.loading = false;
            }).catch(res => {
                this.loading = false;
                console.log(res);
            })
        },
        getRegion(value,obj,isInit = false) {

            let level = 1;
            if (obj != 1) {
                level = parseInt(obj.data.attrs['data-level']);
            }
            if (isInit) {
                this.search.province_id = 0;
            }
            this.$http.api('platform/site/getRegion', {
                level: level,
                parent_id: value,
                province_id: this.search.province_id,
                city_id: this.search.city_id,
                district_id: this.search.district_id,
                is_init: isInit ? 1 : 0
            }).then(res => {
                if (level == 1) {
                    this.provinceData = res.lists;
                }
                if (level == 2) {
                    this.cityData = res.lists;
                    this.search.city_id = null;
                    this.search.district_id = null;
                    this.search.street_id = null;
                }
                if (level == 3) {
                    this.districtData = res.lists
                    this.search.street_id = null;
                }
                if (level == 4) {
                    this.streetData = res.lists
                    this.form.street_id = res.lists[0].id;
                }

            }).catch(res => {
                console.log(res);
            })
        },
        undercarriage(record) {
            this.$confirm({
                title: '确认下架这个项目吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/undercarriageProject', {
                            project_id: record.project_id,
                        }).then(res => {
                            this.$message.success('下架成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },

        grounding(record) {
            this.$confirm({
                title: '确认上架这个项目吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/groundingProject', {
                            project_id: record.project_id,
                        }).then(res => {
                            this.$message.success('上架成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },
        //
        // delProjectAct(record) {
        //     this.$confirm({
        //         title: '确认删除这个项目吗？',
        //         okText: "确定",
        //         okType: "danger",
        //         cancelText: "取消",
        //         onOk: () => {
        //             return new Promise((resolve, reject) => {
        //                 this.$http.api('platform/delProject', {
        //                     project_id: record.project_id,
        //                 }).then(res => {
        //                     this.$message.success('删除成功', 1, () => {
        //                         this.getLists();
        //                     });
        //                 }).catch(res => {
        //                     console.log(res);
        //                 }).finally(() => {
        //                     resolve();
        //                 })
        //             })
        //         }
        //     })
        // },
        changeStatus() {
            this.pagination.current = 1;
            this.getLists();
        },
        siteManageExport(){
            console.log(this.eUrl)

        },
        siteBaseExport(){

            // this.$http.api('platform/site/exportData', {
            //     type: 'siteBaseExport',
            // }).then(res => {
            //     console.log(res)
            // }).catch(res => {
            //     console.log(res);
            // })
            // console.log(this.eUrl+'&type=siteBaseExport');
             window.location.href = this.eUrl+'&type=siteBaseExport';
        },
        addSiteAct() {
            this.$router.replace('/siteAdmin/edit');
        },
        editProjectAct(record) {
            this.$router.push('/siteAdmin/edit?id=' + record.shop_id);
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        del(record){
            if(record.is_open === 1){
                this.$message.error('此站点为启用状态无法删除');
                return false;
            }
            this.$confirm({
                title: '确认删除这个站点吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/site/delSite', {
                            shop_id: record.shop_id,
                        }).then(res => {
                            this.$message.success('删除成功', 1, () => {
                                this.getLists();
                            });
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        }
    }
}
</script>

<style>
.site-index-cover-img{
    width: 40px;
    height: 40px;
    border: 1px solid #FFFFFF;
}
</style>
